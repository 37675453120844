/* Imports */
@import url('./global.css');
@import url('./header.css');
@import url('./buttons.css'); /* Changed 'Buttons.css' to 'buttons.css' to match the correct case */
@import url('./main.css');
@import url('./index.css');
@import url('./variables.css');
@import url('./Portfolio.css'); /* Added import for Portfolio.css */
@import url('./Contact.css');
@import url('./Form.css');
@import url('./Footer.css'); /* Changed 'Footer.css' to 'footer.css' to match the correct case */

/* Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  font-family: var(--font-family);
  font-size: 16px; /* Base font size */
  background-image: url('../images/bluegradient.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--text-color);
  text-decoration: none;
  font-weight: bold;
}

a:active {
  color: yellowgreen;
}

a:hover {
  color: var(--secondary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: var(--text-color);
  font-size: 0.875rem; /* 14px */
}

p {
  text-indent: 1.5rem;
  text-align: left !important;
  font-size: 1.1rem!important; /* 16px */
}

/* Global img styles */
img {
  width: 60px;
  margin: var(--margin);
}

/* Classes */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container p {
  font-size: 0.75rem;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 1rem; /* 16px */
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.study {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  font-size: 1rem; /* 16px */
  margin: 1rem !important;
  padding: 1rem;
  width: auto !important;
  background-color: var(--background-color) !important;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem; /* 16px */
  margin: 0.5rem;
}

.about_main div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem;
  border-radius: 6px;
  width: 90%;
  border: 3.5px ridge var(--primary-color);
  background-color: rgba(0, 0, 59, 0.8);
}

.center-text {
  text-align: center;
  font-size: 1.75rem; /* 16px */
  font-family: var(--font-family);
  color: var(--primary-color);
  font-weight: 600;
}

p.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-size: 1.125rem; /* 16px */
  margin: 2rem;
}





#W3Schools {
  border-radius: 0px !important;
}

.indented-list {
  margin-left: 2rem;
  font-size: 1rem; /* 16px */
}

/* IDs */
#githubRepo {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1.25rem; /* 16px */
  text-align: center;
  text-indent: 0 !important;
}

.GitHub {
  width: 60px !important; /* Increased by 33% from 45px */
  height: auto;
  border-radius: 10px !important;
  background-color: white;
}

#anchor {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1rem; /* 16px */
  text-align: center;
  background-color: var(--background-color);
  border-radius: 6px;
  border: 3px solid var(--primary-color);
}

#profile {
  margin-bottom: var(--margin);
  border-radius: 50%;
  width: 150px;
}

#Codecademy {
  background-color: white;
  padding: 1rem;
}


#StackOverFlow {
  height: 60px !important; /* Increased by 33% from 60px */
  width: auto;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
}

#MDN {
  height: 60px !important; /* Increased by 33% from 60px */
  width: auto !important;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.734);
}

/* Classes with multiple selectors */
.about-me-email,
.anchor,
#anchor {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1rem; /* 16px */
  text-align: center;
  padding: 0.5rem;
}

.about-me-email:hover,
.anchor:hover,
#anchor:hover {
  color: var(--secondary-color);
}

.about-me-email:active,
.anchor:active,
#anchor:active {
  color: yellowgreen;
}

.about-me-email:visited {
  color: var(--primary-color);
}

.about-me-email:focus {
  margin-top: 0;
  margin-bottom: 2.5rem;
}

.anchor {
  text-align: left;
}


.leetCode, #LeetCode {
  border-radius: 14px !important;
}

.form-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem; /* 16px */
  margin: 0.5rem;
  background-color: rgba(0, 0, 59, 0.8);

}

.study-img, .social-icons img {
  height: 70px !important;
  width: auto !important;
  margin: 1rem;
  border-radius: 8px;
  cursor: pointer;
}

.study-img:hover, .social-icons img:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.study-img:active, .social-icons img:active {
  transform: scale(0.9);
  transition: transform 0.1s ease;
}

.social-icons img.Discord {
  height: 80px !important;
  width: auto !important;
}

.Stack {
  width: 75px !important;
  height: auto;
  border-radius: 8px;
  background-color: white;
}

@media (max-width: 768px) {
  .about_main, .section {
    padding: 0!important;
    font-size: 1.125rem; /* 18px */
  }

  .center-text {
    font-size: 1.5rem; /* 24px */
  }

  #profile {
    width: 140px !important;
  }

  p.p {
    font-size: 1rem!important; /* 16px */
  }

  .study-img, .social-icons img {
    height: 50px !important;
    width: auto !important;
  }
}

@media (max-width: 1024px) {
  .about_main, .section {
    padding: 0.5rem;
    font-size: 1rem; /* 16px */
  }

  .center-text {
    font-size: 1.5rem; /* 24px */
  }

  #profile {
    width: 130px !important;
  }

  p.p {
    font-size: 1rem!important; /* 16px */
  }
}

@media (max-width: 768px) {
  .about_main, .section {
    padding: 0.5rem;
    font-size: 0.875rem; /* 14px */
  }

  .about_main div{
    margin: 2rem;
  }


  .social-icons img.Discord {
    height: 60px !important;
    width: auto !important;
  }

  .study-img, .social-icons img {
    margin: 0.5rem;
  }

  .center-text {
    font-size: 1.25rem; /* 20px */
  }

  img {
    width: 50px;
  }

  #profile {
    width: 120px !important;
  }

  p.p {
    font-size: 1rem!important; /* 16px */
  }

  #footer {
    font-size: 0.875rem!important;
  }
}

@media (max-width: 480px) {
  .about_main {
    padding: 0.5rem;
    font-size: 0.75rem; /* 12px */
  }

  .center-text {
    font-size: 1.25rem; /* 16px */
  }

  #profile {
    width: 100px !important;
  }

  p.p {
    font-size: 0.9rem!important; /* 14px */
  }

  .about_main div {
    margin: 2rem;
  }

  .social-icons {
    display: grid !important;
    grid-template-columns: auto auto auto;
  }

  .social-icons {
    display: grid !important;
    grid-template-columns: auto auto auto;
  }

  .social-icons img {
    height: 60px !important;
    width: auto !important; 
    margin: 1rem;
    border-radius: 8px;
  }

  .Stack.Overflow {
    width: 80px !important;
    height: auto !important;
  }
}
