:root {
  --margin-small: 0.5rem;
  --margin-medium: 0.75rem;
  --padding-small: 0.5rem;
  --padding-medium: 0.75rem;
  --font-size-small: 1rem;
  --font-size-medium: 1.25rem;
  --font-size-large: 1.5rem;
  --text-color: #ffffff;
  --font-family: Arial, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  flex: 1;
  color: var(--text-color);
}

.logo-container {
  gap: 1.5rem;
}

.logo {
  height: auto;
  margin: var(--margin-small);
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.logos {
  background-color: var(--background-color);
  border: 3.5px ridge var(--primary-color);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 2rem 2.5rem 2rem; /* Default margin */
  padding: 1rem;
  border-radius: 6px;
}

#Angular {
  transform: scale(1.15);
}

#Jest {
  background-color: white !important;
  padding: var(--padding-small);
  border-radius: 50%;
  width: 60px;
}

#GitHub {
  background-color: white;
  padding: 0;
  border-radius: 16px;
}

#Git {
  padding: 0;
  height: 25px !important;
  width: auto !important;
}

#NPM {
  padding: 0;
  height: 50px !important;
  width: auto !important;
}

#Vercel, #NextJS {
  background-color: white;
  border-radius: 50%;
}

#ExpressJS {
  background-color: black;
  padding: var(--padding-small);
  border-radius: 50%;
}

#Flask {
  background-color: white;
  padding: var(--padding-small);
  height: 55px;
  width: 55px;
  border-radius: 10px;
}

#HTML, #Git {
  transform: scale(1.25);
}

#PostgreSQL {
  background-color: white;
  padding: 0.25rem;
  border-radius: 8px;
}

#TailwindCSS, #Bootstrap {
  padding: var(--padding-small);
  transform: scale(1.56);
}

#profile {
  width: 175px !important;
  border: 3.5px ridge var(--primary-color); 
  margin: 1.5rem;
  border-radius: 8px;
}

#name {
  font-size: var(--font-size-large);
  color: var(--primary-color);
}

#experience, #tech {
  font-size: var(--font-size-medium);
  color: var(--primary-color);
  margin-top: var(--margin-small);
}

#contact {
  font-size: var(--font-size-small);
  color: var(--text-color);
  margin: var(--margin-small);
  color: var(--primary-color);
  font-style: bold;
}

#VisualStudio {
  width: 100px !important;
  margin: 0 !important;
}

.main p {
  font-size: 1em;
}

p.p {
  line-height: 1.6;
  text-align: center;
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  width: 90%;
}

.small-logo {
  width: 50px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.small-logo:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.small-logo:active {
  transform: scale(0.9);
}

#C, #C\+\+, #C\# {
  height: 70px;
  width: auto;
}

#Redux {
  background-color: white;
  border-radius: 6px;
  height: 55px;
  width: auto;
}

#ExpressJS, #NodeJS, #Python-Django {
  height: 60px !important;
  width: auto !important;
}

#toggleIcon {
  transform: none;
  transition: transform 0.3s ease-in-out;
}

#experience.headings, #tech.headings {
  font-weight: 600 !important;
}

@media (max-width: 1024px) {
  #profile {
    width: 140px;
  }

  .logo-container {
    gap: 1.5rem;
  }

  .main p {
    font-size: 1.2rem;
  }

  .logos {
    margin: 1rem 2rem; /* Adjusted for medium screens */
  }
}

@media (max-width: 768px) {
  #name {
    font-size: calc(var(--font-size-large) - 0.25rem);
  }

  #experience, #tech {
    font-size: calc(var(--font-size-medium) - 0.25rem);
  }

  #contact {
    font-size: calc(var(--font-size-small) - 0.25rem);
  }

  p.p {
    font-size: calc(var(--font-size-small) - 0.125rem);
  }

  #profile {
    width: 120px !important;
    margin-top: 1rem !important;
  }

  .main p {
    font-size: 1rem;
  }

  .header {
    background: none;
    border: none;
  }

  .container {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .menu-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 50%; /* Increased height by 50% */
    z-index: 1000;
    flex-direction: column;
    align-items: flex-end;
  }

  .menu-container.open {
    display: flex !important;
    background-color: rgba(0, 0, 0, 0.5);
  }

  #toggleIcon {
    position: fixed;
    display: flex;  
    justify-self: flex-end;
    align-self: center;
    position: fixed;
    top: 0;
    right: 0;
    margin: 0.5rem;
    z-index: 1100;
  }

  .nav-items, .nav-items > * {
    margin: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-start !important;
    padding: var(--margin-small) !important;
    background-color: transparent !important;
  }

  .listItem > a {
    width: 100% !important;
    padding: var(--padding-small) !important;
    margin: var(--margin-small) !important;
    font-size: calc(var(--font-size-medium) - 0.25rem) !important;
    background-color: transparent !important;
  }

  .logos {
    margin: 1rem 1.5rem; /* Adjusted for small screens */
  }
}

@media (max-width: 480px) {
  #profile {
    width: 100px !important;
  }

  .logos {
    margin: 1rem 1rem; /* Adjusted for extra small screens */
  }
}
