/* General Styles */

h2 {
  color: var(--color-primary);
  font-weight: 500;
  font-size: var(--font-size-large);
  text-align: center;
  margin: 0.5rem;
  font-weight: 600;
}

span {
  color: var(--primary-color);
  font-weight: 500;
  font-size: var(--font-size-large);
  text-align: center;
  margin: 0.5rem;
}

.input-style {
  font-size: 1rem; /* 16px */
  margin: 1rem;
  padding: 0.5rem;
  width: 100% !important;
  border-radius: 6px;
  text-align: center;
  display: flex;
}

.label-style {
  font-size: 1.25rem; /* 20px */
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70% !important;
  border: 3.5px ridge var(--primary-color) !important;
  padding: 1rem;
  border-radius: 6px;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5rem 1rem;
  width: 100%;
}

.form-style {
  padding: 2rem;
}

/* Navbar Styles */

/* Media Queries */
@media (max-width: 1024px) {
  form {
    width: 80% !important;
  }

  .input-style {
    width: 150%; /* Adjusted for medium screens */
  }
}

@media (max-width: 768px) {
  form {
    width: 70% !important;
  }

  .input-style {
    width: 120%; /* Adjusted for small screens */
    font-size: 0.875rem; /* Adjusted font size */
  }

  .form-style {
    padding: 1rem; /* Adjusted padding */
  }

  span {
    font-size: var(--font-size-medium);
  }
}

@media (max-width: 480px) {
  form {
    width: 90% !important;
  }

  .input-style {
    width: 100%; /* Adjusted for extra small screens */
    font-size: 0.75rem; /* Adjusted font size */
  }

  .form-style {
    padding: 0.75rem; /* Adjusted padding */
  }
}
