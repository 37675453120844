@import url('./variables.css');

header {
    position: relative; /* Ensure header can be adjusted */
    z-index: 1000; /* Place header above the body */
    top: 0; /* Keep it at the top */
    background-color: var(--background-color);
    border-bottom: 5px ridge var(--primary-color);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    text-align: center;
    padding: 0!important;
    overflow-y: hidden; /* Prevent vertical scrolling */
}

footer {
    background-color: var(--background-color);
    border-top: 3px solid var(--primary-color);
    margin-top: auto;
    text-align: center;
}

.listItem > a {
    align-items: center;
    background-color: var(--background-color);
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    font-size: 2.25rem !important;
    margin: 0;
    padding: 0;
}

.menu-container {
    align-items: center;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column !important;
    justify-content: center;
}

.menu-button {
    background-color: var(--background-color);
    border: none;
    color: var(--text-color);
    cursor: pointer;
    font-size: 1.25rem;
    position: relative;
    top: 0;
    left: 0;
    padding: 0.5rem 1rem;
    transition: color 0.2s ease, transform 0.2s ease, background-color 0.2s ease;
}

.menu-button:hover {
    background-color: var(--secondary-color); /* Change background on hover */
}

.nav-container {
    align-items: center;
    background-color: var(--background-color);
    border-bottom: 3px solid var(--primary-color);
    display: flex;
    justify-content: center;
}

.nav-items {
    align-items: center;
    background-color: var(--background-color);
    display: flex;
    flex: 1; /* Added missing semicolon */
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-x: hidden !important; /* Prevent horizontal scroll */
    overflow-y: hidden !important; /* Prevent vertical scroll */
}

.nav-items a {
    color: var(--text-color);
    display: flex;
    font-family: 'Arial Black', Gadget, sans-serif;
    justify-content: center;
    align-items: center;
    margin: 1.5rem !important;
    padding: 0.5rem 2.5rem;
    text-decoration: none;
    transition: color 0.2s ease, transform 0.2s ease;
}

.nav-items a:hover {
    color: var(--secondary-color);
    transform: scale(1.1);
    overflow-y: hidden; /* Ensure no vertical scroll on hover */
}

.nav-items a:active {
    color: aqua;
    transform: scale(0.95);
    border: 2.5px solid aqua;
    border-radius: 6px;
    padding: 0 0.5rem;
}

.nav-items .listItem:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease;
}

.nav-items .listItem:active {
  transform: scale(0.95);
  transition: transform 0.2s ease;
}

.nav-link {
    margin: 1.5rem !important;
}

.nav-link.active {
  color: var(--primary-color);
  transform: scale(1.1) !important;
  border: 2.5px solid var(--primary-color) !important;
  padding: 0 0.5rem !important;
  border-radius: 6px !important;
  background-color: rgba(0, 0, 59, 0.8);
}

#toggleIcon {
    position: relative; /* Position relative to the body */
    top: 1rem; /* Adjust as needed */
    right: 1rem; /* Adjust as needed */
    height: 37.5px !important;
    width: 37.5px !important;
    z-index: 1100; /* Ensure toggleIcon is above the header */
    opacity: 0.6;
}

/* Universal style for all headings */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Arial Black', Gadget, sans-serif; /* Set a consistent font-family for all headings */
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
    .nav-items a {
        font-size: 1.5rem!important;
    }
}

@media (max-width: 900px) {
    .nav-items a {
        font-size: 1.3rem!important;
    }
}
